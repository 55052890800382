import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import { Container, GenericPageWrapper, Inner } from '../components/Elements';
import breakpoints from '../consts/breakpoints';
import useWindowSize from '../hooks/useWindowSize';

import destopImage from '../images/404-desktop.svg';
import mobileImage from '../images/404-mobile.svg';

const Wrapper = styled(GenericPageWrapper)`
  padding-bottom: 19rem;

  ${props => props.theme.mq.medium`padding-bottom: 13rem;`}
  .content {
    position: relative;
    text-align: center;
    width: 100%;
    .content-inner {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      text-align: center;
      transform: translateY(-50%);
      p {
        font-size: 2rem;
        line-height: 2.2rem;
        margin-bottom: 2.4rem;
        ${props => props.theme.font.p2}
      }
      a {
        ${props => props.theme.font.buttonMediumPrimary}
      }
    }
  }
`;

const NotFoundPage = () => {
  const windowSize = useWindowSize();
  const image =
    windowSize.width < breakpoints.tablet ? mobileImage : destopImage;
  return (
    <Wrapper>
      <Container>
        <Inner>
          <div className="content">
            <img src={image} alt="404 icon" />
            <div className="content-inner">
              <p>The page you&apos;re looking for can&apos;t be found.</p>
              <Link to="/">Return Home</Link>
            </div>
          </div>
        </Inner>
      </Container>
    </Wrapper>
  );
};

export default NotFoundPage;
